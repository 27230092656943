<template>

  <div>
    <div id="devise-admin-content">
      <action-bar>
        <li class="dvs-btn dvs-btn-sm dvs-mb-2" :style="theme.actionButton" @click.prevent="showCreateDonor">
          Create New Donor
        </li>
      </action-bar>

      <h2 class="dvs-mb-10" :style="{color: theme.panel.color}">Current Donors</h2>

      <div v-for="donor in donors" :key="donor.id" class="dvs-mb-6 dvs-flex dvs-justify-between dvs-items-center">
        <div class="dvs-min-w-2/5 dvs-text-base dvs-font-bold dvs-pr-8">
          {{ donor.name }}<br>
          <span class="dvs-font-normal dvs-text-xs" v-if="donor.season">{{ donor.season.years }} Season</span>
        </div>
        <div class="dvs-w-2/5 dvs-px-8 dvs-flex dvs-justify-end">
          <button class="dvs-btn dvs-btn-xs dvs-mr-2" @click="showEditDonor(donor)" :style="theme.actionButtonGhost">Edit</button>
          <button class="dvs-btn dvs-btn-xs" v-devise-alert-confirm="{callback: requestDeleteDonor, arguments: donor, message: 'Are you sure you want to delete this donor?'}" :style="theme.actionButtonGhost">Delete</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'DonorsIndex',
  data () {
    return {
      modulesToLoad: 1
    }
  },
  mounted () {
    this.retrieveAllDonors()
  },
  watch: {
    language() {
      this.retrieveAllDonors();
    }
  },
  methods: {
    ...mapActions([
      'getDonors',
      'deleteDonor'
    ]),
    showEditDonor (donor)  {
      this.$router.push({name: 'devise-donors-edit', params: {donorId: donor.id}})
    },
    showCreateDonor (donor)  {
      this.$router.push({name: 'devise-donors-create'})
    },
    requestDeleteDonor (donor) {
      let self = this
      this.deleteDonor(donor).then(function () {
        self.retrieveAllDonors()
      })
    },
    retrieveAllDonors (loadbar = true) {
      this.getDonors().then(function () {
        if (loadbar) {
          devise.$bus.$emit('incrementLoadbar', self.modulesToLoad)
        }
      })
    },
    originalDonor(id) {
      return this.donors.data.find(donor => donor.id === id)
    }
  },
  computed: {
    ...mapGetters([
      'donors'
    ])
  }
}
</script>
