<template>

  <div>

    <fieldset class="dvs-fieldset mb-8">
      <label>Name</label>
      <input
        type="text"
        v-model="value.name"
        placeholder="Name of the Concert"
      >
    </fieldset>

    <fieldset class="dvs-fieldset mb-8">
      <label>Image</label>
      <image-field v-model="value.image"></image-field>
    </fieldset>

    <fieldset class="dvs-fieldset mb-8">
      <label>Season</label>
      <select v-model="value.season_id">
        <option :value="0">None</option>
        <option
          v-for="season in seasons"
          :key="season.id"
          :value="season.id"
        >{{ season.years }}</option>
      </select>
    </fieldset>

    <fieldset class="dvs-fieldset mb-8">
      <label>Date / Time</label>
      <devise-datepicker
        v-model="value.date"
        :settings="{date:true,time:true}"
      />
    </fieldset>

    <fieldset class="dvs-fieldset mb-8">
      <label>
        <input
          type="checkbox"
          v-model="value.time_tbd"
        >
        Time TBD (Will hide the time)
      </label>
    </fieldset>

    <fieldset class="dvs-fieldset mb-8">
      <label>Description</label>
      <devise-wysiwyg v-model="value.description"></devise-wysiwyg>
    </fieldset>

    <fieldset class="dvs-fieldset mb-8">
      <label>Secondary Performing Group</label>
      <input
        type="text"
        v-model="value.secondary_group"
        placeholder="Group Name"
      >
    </fieldset>

    <fieldset class="dvs-fieldset mb-8">
      <label>Location</label>
      <select v-model="value.location_id">
        <option :value="0">To Be Determined (TBD)</option>
        <option
          v-for="location in locations"
          :key="location.id"
          :value="location.id"
        >{{ location.name }}</option>
      </select>
    </fieldset>

    <fieldset class="dvs-fieldset mb-8">
      <label>Tickets Link</label>
      <input
        type="text"
        v-model="value.tickets_link"
        placeholder="Link to where to purchase tickets"
      >
    </fieldset>

    <fieldset class="dvs-fieldset mb-8">
      <label>
        <input
          type="checkbox"
          v-model="value.is_subscription"
        >
        Subscription Concert
      </label>
    </fieldset>

    <fieldset class="dvs-fieldset mb-8">
      <label>Page Details</label>
      <devise-wysiwyg v-model="value.page_details"></devise-wysiwyg>
    </fieldset>

    <fieldset class="dvs-fieldset mb-8">
      <label>Page Slug<br>Should only contain alphanumeric characters, slashes and
        dashes<br>(example "/my-new-concert" or "/season/2021-2022/my-concert")</label>
      <input
        type="text"
        v-model="value.page_slug"
      >
    </fieldset>

  </div>

</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DeviseDatepicker from 'devisephp-interface/src/components/utilities/DatePicker.vue';
import DeviseWysiwyg from 'devisephp-interface/src/components/utilities/Wysiwyg.vue';
import ImageField from 'devisephp-interface/src/components/utilities/images/ImageField.vue';

export default {
  name: 'ConcertsForm',
  mounted() {
    this.getSeasons();
    this.getLocations();
  },
  methods: {
    ...mapActions([
      'getLocations',
      'getSeasons',
    ]),
    updateValue() {
      // Emit the number value through the input event
      this.$emit('input', this.localValue);
      this.$emit('change', this.localValue);
    },
  },
  computed: {
    ...mapGetters([
      'seasons',
      'locations',
    ]),
  },
  components: {
    DeviseDatepicker,
    DeviseWysiwyg,
    ImageField,
  },
  watch: {
    value() {
      this.updateValue();
    },
  },
  props: ['value'],
};
</script>
