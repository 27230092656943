<template>

  <div>

    <fieldset class="dvs-fieldset mb-8">
      <label>Name</label>
      <input
        type="text"
        v-model="value.name"
        placeholder="Name of the Donor"
      >
    </fieldset>

    <fieldset class="dvs-fieldset mb-8">
      <label>Donor Level</label>
      <select v-model="value.donor_level_id">
        <option
          v-for="donorLevel in donorLevels"
          :key="donorLevel.id"
          :value="donorLevel.id"
        >{{ donorLevel.label }} ({{donorLevel.range}})</option>
      </select>
    </fieldset>

  </div>

</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'DonorsForm',
  mounted() {
    this.getDonorLevels();
  },
  methods: {
    ...mapActions([
      'getDonorLevels',
    ]),
    updateValue() {
      // Emit the number value through the input event
      this.$emit('input', this.localValue);
      this.$emit('change', this.localValue);
    },
  },
  computed: {
    ...mapGetters([
      'donorLevels',
    ]),
  },
  watch: {
    value() {
      this.updateValue();
    },
  },
  props: ['value'],
};
</script>
