<template>

  <div>

    <div id="devise-admin-content" v-if="editLocation">

      <h2 class="dvs-mb-10" :style="{color: theme.panel.color}">Editing Location: {{ editLocation.name }}</h2>

      <location-form v-model="editLocation"></location-form>

      <button class="dvs-btn" @click="requestEditLocation" :disabled="editInvalid" :style="theme.actionButton">Edit</button>
      <button class="dvs-btn" @click="goToPage('devise-locations-index')" :style="theme.actionButtonGhost">Cancel</button>
    </div>

  </div>

</template>

<script>
import LocationForm from './Form'

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'LocationsEdit',
  data () {
    return {
      modulesToLoad: 1,
      editLocation: null
    }
  },
  mounted () {
    this.retrieveAllLocations()
  },
  methods: {
    ...mapActions([
      'getLocations',
      'updateLocation'
    ]),
    requestEditLocation () {
      this.updateLocation({location: this.location(this.$route.params.locationId), data: this.editLocation}).then(() => {
        this.goToPage('devise-locations-index')
      })
    },
    retrieveAllLocations (loadbar = true) {
      let self = this
      this.getLocations().then(function () {
        self.$set(self, 'editLocation', Object.assign({}, self.location(self.$route.params.locationId)))

        if (loadbar) {
          devise.$bus.$emit('incrementLoadbar', self.modulesToLoad)
        }
      })
    }
  },
  computed: {
    ...mapGetters([
      'location'
    ]),
    editInvalid () {
      return this.editLocation.name === null ||
             this.editLocation.description === null
    }
  },
  components: {
    LocationForm
  }
}
</script>
