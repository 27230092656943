<template>

  <div>
    
    <div id="devise-admin-content" v-if="seasonToCreate">

      <h2 class="dvs-mb-10" :style="{color: theme.panel.color}">Create Seasons: {{ seasonToCreate.name }}</h2>

      <seasons-form v-model="seasonToCreate"></seasons-form>

      <button class="dvs-btn" :style="theme.actionButton" @click="requestCreateSeasons" :disabled="createInvalid">
        Create
      </button>
      <button class="dvs-btn" :style="theme.actionButtonGhost" @click="goToPage('devise-seasons-index')">Cancel</button>
    </div>

  </div>

</template>

<script>
  import {mapActions} from 'vuex'
  
  import SeasonsForm from './Form'
  
  export default {
    name: 'SeasonCreate',
    data() {
      return {
        seasonToCreate: {
          years: '',
          description: ''
        }
      }
    },
    methods: {
      ...mapActions([
        'createSeason'
      ]),
      requestCreateSeasons() {
        this.createSeason(this.seasonToCreate).then(() => {
          this.goToPage('devise-seasons-index')
        })
      }
    },
    computed: {
      createInvalid() {
        return this.seasonToCreate.name === null
      }
    },
    components: {
      SeasonsForm
    }
  }
</script>
