<template>

  <div>

    <div id="devise-admin-content" v-if="editDonor">

      <h2 class="dvs-mb-10" :style="{color: theme.panel.color}">Editing Donor: {{ editDonor.name }}</h2>

      <donor-form v-model="editDonor"></donor-form>

      <button class="dvs-btn" @click="requestEditDonor" :disabled="editInvalid" :style="theme.actionButton">Edit</button>
      <button class="dvs-btn" @click="goToPage('devise-donors-index')" :style="theme.actionButtonGhost">Cancel</button>
    </div>

  </div>

</template>

<script>
import DonorForm from './Form'

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'DonorsEdit',
  data () {
    return {
      modulesToLoad: 1,
      editDonor: null
    }
  },
  mounted () {
    this.retrieveAllDonors()
  },
  methods: {
    ...mapActions([
      'getDonors',
      'updateDonor'
    ]),
    requestEditDonor () {
      this.updateDonor({donor: this.donor(this.$route.params.donorId), data: this.editDonor}).then(() => {
        this.goToPage('devise-donors-index')
      })
    },
    retrieveAllDonors (loadbar = true) {
      let self = this
      this.getDonors().then(function () {
        self.$set(self, 'editDonor', Object.assign({}, self.donor(self.$route.params.donorId)))

        if (loadbar) {
          devise.$bus.$emit('incrementLoadbar', self.modulesToLoad)
        }
      })
    }
  },
  computed: {
    ...mapGetters([
      'donor'
    ]),
    editInvalid () {
      return this.editDonor.name === null ||
             this.editDonor.description === null
    }
  },
  components: {
    DonorForm
  }
}
</script>
