<template>

  <div>

    <fieldset class="dvs-fieldset mb-8">
      <label>Section</label>
      <input
        type="text"
        v-model="value.section"
        placeholder="Name of the Section"
      >
    </fieldset>

    <fieldset class="dvs-fieldset mb-8">
      <label>Sub-Section</label>
      <input
        type="text"
        v-model="value.subsection"
        placeholder="Name of the Sub-Section"
      >
    </fieldset>

    <fieldset class="dvs-fieldset mb-8">
      <label>Name</label>
      <input
        type="text"
        v-model="value.name"
        placeholder="Player Name"
      >
    </fieldset>

    <fieldset class="dvs-fieldset mb-8">
      <label>Title</label>
      <input
        type="text"
        v-model="value.title"
        placeholder="Title"
      >
    </fieldset>

    <fieldset class="dvs-fieldset mb-8">
      <label>Image</label>
      <image-field v-model="value.image"></image-field>
    </fieldset>

    <fieldset class="dvs-fieldset mb-8">
      <label>Bio</label>
      <devise-wysiwyg v-model="value.bio"></devise-wysiwyg>
    </fieldset>

  </div>

</template>

<script>
import DeviseWysiwyg from 'devisephp-interface/src/components/utilities/Wysiwyg.vue';
import ImageField from 'devisephp-interface/src/components/utilities/images/ImageField.vue';

export default {
  name: 'ConcertsForm',
  methods: {
    updateValue() {
      // Emit the number value through the input event
      this.$emit('input', this.localValue);
      this.$emit('change', this.localValue);
    },
  },
  components: {
    DeviseWysiwyg,
    ImageField,
  },
  watch: {
    value() {
      this.updateValue();
    },
  },
  props: ['value'],
};
</script>
