// import commonUtils from 'devisephp-interface/src/vuex/utils/common';

const actions = {

  // Calendar
  getCalendarEvents(context) {
    return new Promise((resolve) => {
      window.axios.get(`${context.state.devise.api.baseUrl}calendar-events`).then((response) => {
        context.commit('setCalendarEvents', response.data);
        resolve(response);
      }).catch((error) => {
        window.deviseSettings.$bus.$emit('showError', error);
      });
    }).catch((error) => {
      window.deviseSettings.$bus.$emit('showError', error);
    });
  },


  // Concert
  getAllConcerts(context) {
    return new Promise((resolve) => {
      window.axios.get(`${context.state.devise.api.baseUrl}concerts`).then((response) => {
        context.commit('setConcerts', response.data);
        resolve(response);
      }).catch((error) => {
        window.deviseSettings.$bus.$emit('showError', error);
      });
    }).catch((error) => {
      window.deviseSettings.$bus.$emit('showError', error);
    });
  },

  getSubscriptionConcerts(context) {
    return new Promise((resolve) => {
      window.axios.get(`${context.state.devise.api.baseUrl}subscription-concerts`).then((response) => {
        context.commit('setConcerts', response.data);
        resolve(response);
      }).catch((error) => {
        window.deviseSettings.$bus.$emit('showError', error);
      });
    }).catch((error) => {
      window.deviseSettings.$bus.$emit('showError', error);
    });
  },

  createConcert(context, concert) {
    return new Promise((resolve) => {
      window.axios.post(`${context.state.devise.api.baseUrl}concerts/`, concert).then((response) => {
        window.deviseSettings.$bus.$emit('showMessage', { title: 'Success!', message: `${concert.name} has been created.` });
        context.commit('createConcerts', response.data);
        resolve(response);
      }).catch((error) => {
        window.deviseSettings.$bus.$emit('showError', error);
      });
    }).catch((error) => {
      window.deviseSettings.$bus.$emit('showError', error);
    });
  },

  updateConcert(context, payload) {
    return new Promise((resolve) => {
      window.axios.put(`${context.state.devise.api.baseUrl}concerts/${payload.concert.id}`, payload.data).then((response) => {
        window.deviseSettings.$bus.$emit('showMessage', { title: 'Success!', message: `${payload.data.name} has been saved.` });
        context.commit('updateConcerts', { concert: payload.concert, data: response.data });
        resolve(response);
      }).catch((error) => {
        window.deviseSettings.$bus.$emit('showError', error);
      });
    }).catch((error) => {
      window.deviseSettings.$bus.$emit('showError', error);
    });
  },

  deleteConcert(context, concert) {
    return new Promise((resolve) => {
      window.axios.delete(`${context.state.devise.api.baseUrl}concerts/${concert.id}`).then((response) => {
        window.deviseSettings.$bus.$emit('showMessage', { title: 'Success!', message: `${concert.name} has been deleted.` });
        context.commit('deleteConcert', concert);
        resolve(response);
      }).catch((error) => {
        window.deviseSettings.$bus.$emit('showError', error);
      });
    }).catch((error) => {
      window.deviseSettings.$bus.$emit('showError', error);
    });
  },


  // Donors
  getDonors(context, language) {
    return new Promise((resolve) => {
      window.axios.get(`${context.state.devise.api.baseUrl}donors?langage=${language}`).then((response) => {
        context.commit('setDonors', response.data);
        resolve(response);
      }).catch((error) => {
        window.deviseSettings.$bus.$emit('showError', error);
      });
    }).catch((error) => {
      window.deviseSettings.$bus.$emit('showError', error);
    });
  },

  createDonor(context, donor) {
    return new Promise((resolve) => {
      window.axios.post(`${context.state.devise.api.baseUrl}donors/`, donor).then((response) => {
        window.deviseSettings.$bus.$emit('showMessage', { title: 'Success!', message: `${donor.name} has been created.` });
        context.commit('createDonors', response.data);
        resolve(response);
      }).catch((error) => {
        window.deviseSettings.$bus.$emit('showError', error);
      });
    }).catch((error) => {
      window.deviseSettings.$bus.$emit('showError', error);
    });
  },

  updateDonor(context, payload) {
    return new Promise((resolve) => {
      window.axios.put(`${context.state.devise.api.baseUrl}donors/${payload.donor.id}`, payload.data).then((response) => {
        window.deviseSettings.$bus.$emit('showMessage', { title: 'Success!', message: `${payload.data.name} has been saved.` });
        context.commit('updateDonors', { donor: payload.donor, data: response.data });
        resolve(response);
      }).catch((error) => {
        window.deviseSettings.$bus.$emit('showError', error);
      });
    }).catch((error) => {
      window.deviseSettings.$bus.$emit('showError', error);
    });
  },

  deleteDonor(context, donor) {
    return new Promise((resolve) => {
      window.axios.delete(`${context.state.devise.api.baseUrl}donors/${donor.id}`).then((response) => {
        window.deviseSettings.$bus.$emit('showMessage', { title: 'Success!', message: `${donor.name} has been deleted.` });
        context.commit('deleteDonor', donor);
        resolve(response);
      }).catch((error) => {
        window.deviseSettings.$bus.$emit('showError', error);
      });
    }).catch((error) => {
      window.deviseSettings.$bus.$emit('showError', error);
    });
  },


  // DonorLevel
  getDonorLevels(context, language) {
    return new Promise((resolve) => {
      window.axios.get(`${context.state.devise.api.baseUrl}donor-levels?langage=${language}`).then((response) => {
        context.commit('setDonorLevels', response.data);
        resolve(response);
      }).catch((error) => {
        window.deviseSettings.$bus.$emit('showError', error);
      });
    }).catch((error) => {
      window.deviseSettings.$bus.$emit('showError', error);
    });
  },

  createDonorLevel(context, donorLevel) {
    return new Promise((resolve) => {
      window.axios.post(`${context.state.devise.api.baseUrl}donor-levels/`, donorLevel).then((response) => {
        window.deviseSettings.$bus.$emit('showMessage', { title: 'Success!', message: `${donorLevel.label} has been created.` });
        context.commit('createDonorLevels', response.data);
        resolve(response);
      }).catch((error) => {
        window.deviseSettings.$bus.$emit('showError', error);
      });
    }).catch((error) => {
      window.deviseSettings.$bus.$emit('showError', error);
    });
  },

  updateDonorLevel(context, payload) {
    return new Promise((resolve) => {
      window.axios.put(`${context.state.devise.api.baseUrl}donor-levels/${payload.donorLevel.id}`, payload.data).then((response) => {
        window.deviseSettings.$bus.$emit('showMessage', { title: 'Success!', message: `${payload.data.label} has been saved.` });
        context.commit('updateDonorLevels', { donorLevel: payload.donorLevel, data: response.data });
        resolve(response);
      }).catch((error) => {
        window.deviseSettings.$bus.$emit('showError', error);
      });
    }).catch((error) => {
      window.deviseSettings.$bus.$emit('showError', error);
    });
  },

  deleteDonorLevel(context, donorLevel) {
    return new Promise((resolve) => {
      window.axios.delete(`${context.state.devise.api.baseUrl}donor-levels/${donorLevel.id}`).then((response) => {
        window.deviseSettings.$bus.$emit('showMessage', { title: 'Success!', message: `${donorLevel.label} has been deleted.` });
        context.commit('deleteDonorLevel', donorLevel);
        resolve(response);
      }).catch((error) => {
        window.deviseSettings.$bus.$emit('showError', error);
      });
    }).catch((error) => {
      window.deviseSettings.$bus.$emit('showError', error);
    });
  },

  // Location
  getLocations(context) {
    return new Promise((resolve) => {
      window.axios.get(`${context.state.devise.api.baseUrl}locations`).then((response) => {
        context.commit('setLocations', response.data);
        resolve(response);
      }).catch((error) => {
        window.deviseSettings.$bus.$emit('showError', error);
      });
    }).catch((error) => {
      window.deviseSettings.$bus.$emit('showError', error);
    });
  },

  getFutureLocations(context) {
    return new Promise((resolve) => {
      window.axios.get(`${context.state.devise.api.baseUrl}future-locations`).then((response) => {
        context.commit('setFutureLocations', response.data);
        resolve(response);
      }).catch((error) => {
        window.deviseSettings.$bus.$emit('showError', error);
      });
    }).catch((error) => {
      window.deviseSettings.$bus.$emit('showError', error);
    });
  },

  createLocation(context, location) {
    return new Promise((resolve) => {
      window.axios.post(`${context.state.devise.api.baseUrl}locations/`, location).then((response) => {
        window.deviseSettings.$bus.$emit('showMessage', { title: 'Success!', message: `${location.name} has been created.` });
        context.commit('createLocations', response.data);
        resolve(response);
      }).catch((error) => {
        window.deviseSettings.$bus.$emit('showError', error);
      });
    }).catch((error) => {
      window.deviseSettings.$bus.$emit('showError', error);
    });
  },

  updateLocation(context, payload) {
    return new Promise((resolve) => {
      window.axios.put(`${context.state.devise.api.baseUrl}locations/${payload.location.id}`, payload.data).then((response) => {
        window.deviseSettings.$bus.$emit('showMessage', { title: 'Success!', message: `${payload.data.name} has been saved.` });
        context.commit('updateLocations', { location: payload.location, data: response.data });
        resolve(response);
      }).catch((error) => {
        window.deviseSettings.$bus.$emit('showError', error);
      });
    }).catch((error) => {
      window.deviseSettings.$bus.$emit('showError', error);
    });
  },

  deleteLocation(context, location) {
    return new Promise((resolve) => {
      window.axios.delete(`${context.state.devise.api.baseUrl}locations/${location.id}`).then((response) => {
        window.deviseSettings.$bus.$emit('showMessage', { title: 'Success!', message: `${location.name} has been deleted.` });
        context.commit('deleteLocation', location);
        resolve(response);
      }).catch((error) => {
        window.deviseSettings.$bus.$emit('showError', error);
      });
    }).catch((error) => {
      window.deviseSettings.$bus.$emit('showError', error);
    });
  },


  // Player
  getPlayers(context) {
    return new Promise((resolve) => {
      window.axios.get(`${context.state.devise.api.baseUrl}players`).then((response) => {
        context.commit('setPlayers', response.data);
        resolve(response);
      }).catch((error) => {
        window.deviseSettings.$bus.$emit('showError', error);
      });
    }).catch((error) => {
      window.deviseSettings.$bus.$emit('showError', error);
    });
  },

  createPlayer(context, player) {
    return new Promise((resolve) => {
      window.axios.post(`${context.state.devise.api.baseUrl}players/`, player).then((response) => {
        window.deviseSettings.$bus.$emit('showMessage', { title: 'Success!', message: `${player.name} has been created.` });
        context.commit('createPlayers', response.data);
        resolve(response);
      }).catch((error) => {
        window.deviseSettings.$bus.$emit('showError', error);
      });
    }).catch((error) => {
      window.deviseSettings.$bus.$emit('showError', error);
    });
  },

  updatePlayer(context, payload) {
    return new Promise((resolve) => {
      window.axios.put(`${context.state.devise.api.baseUrl}players/${payload.player.id}`, payload.data).then((response) => {
        window.deviseSettings.$bus.$emit('showMessage', { title: 'Success!', message: `${payload.data.name} has been saved.` });
        context.commit('updatePlayers', { player: payload.player, data: response.data });
        resolve(response);
      }).catch((error) => {
        window.deviseSettings.$bus.$emit('showError', error);
      });
    }).catch((error) => {
      window.deviseSettings.$bus.$emit('showError', error);
    });
  },

  deletePlayer(context, player) {
    return new Promise((resolve) => {
      window.axios.delete(`${context.state.devise.api.baseUrl}players/${player.id}`).then((response) => {
        window.deviseSettings.$bus.$emit('showMessage', { title: 'Success!', message: `${player.name} has been deleted.` });
        context.commit('deletePlayers', player);
        resolve(response);
      }).catch((error) => {
        window.deviseSettings.$bus.$emit('showError', error);
      });
    }).catch((error) => {
      window.deviseSettings.$bus.$emit('showError', error);
    });
  },

  // Rehearsal
  getRehearsals(context) {
    return new Promise((resolve) => {
      window.axios.get(`${context.state.devise.api.baseUrl}rehearsals`).then((response) => {
        context.commit('setRehearsals', response.data);
        resolve(response);
      }).catch((error) => {
        window.deviseSettings.$bus.$emit('showError', error);
      });
    }).catch((error) => {
      window.deviseSettings.$bus.$emit('showError', error);
    });
  },

  createRehearsal(context, Rehearsal) {
    return new Promise((resolve) => {
      window.axios.post(`${context.state.devise.api.baseUrl}rehearsals/`, Rehearsal).then((response) => {
        window.deviseSettings.$bus.$emit('showMessage', { title: 'Success!', message: `${Rehearsal.date} has been created.` });
        context.commit('createRehearsals', response.data);
        resolve(response);
      }).catch((error) => {
        window.deviseSettings.$bus.$emit('showError', error);
      });
    }).catch((error) => {
      window.deviseSettings.$bus.$emit('showError', error);
    });
  },

  updateRehearsal(context, payload) {
    return new Promise((resolve) => {
      window.axios.put(`${context.state.devise.api.baseUrl}rehearsals/${payload.Rehearsal.id}`, payload.data).then((response) => {
        window.deviseSettings.$bus.$emit('showMessage', { title: 'Success!', message: `${payload.data.date} has been saved.` });
        context.commit('updateRehearsals', { Rehearsal: payload.Rehearsal, data: response.data });
        resolve(response);
      }).catch((error) => {
        window.deviseSettings.$bus.$emit('showError', error);
      });
    }).catch((error) => {
      window.deviseSettings.$bus.$emit('showError', error);
    });
  },

  deleteRehearsal(context, Rehearsal) {
    return new Promise((resolve) => {
      window.axios.delete(`${context.state.devise.api.baseUrl}rehearsals/${Rehearsal.id}`).then((response) => {
        window.deviseSettings.$bus.$emit('showMessage', { title: 'Success!', message: `${Rehearsal.name} has been deleted.` });
        context.commit('deleteRehearsal', Rehearsal);
        resolve(response);
      }).catch((error) => {
        window.deviseSettings.$bus.$emit('showError', error);
      });
    }).catch((error) => {
      window.deviseSettings.$bus.$emit('showError', error);
    });
  },

  // Seasons
  getSeasons(context) {
    return new Promise((resolve) => {
      window.axios.get(`${context.state.devise.api.baseUrl}seasons`).then((response) => {
        context.commit('setSeasons', response.data);
        resolve(response);
      }).catch((error) => {
        window.deviseSettings.$bus.$emit('showError', error);
      });
    }).catch((error) => {
      window.deviseSettings.$bus.$emit('showError', error);
    });
  },

  createSeason(context, season) {
    return new Promise((resolve) => {
      window.axios.post(`${context.state.devise.api.baseUrl}seasons/`, season).then((response) => {
        window.deviseSettings.$bus.$emit('showMessage', { title: 'Success!', message: `${season.years} has been created.` });
        context.commit('createSeasons', response.data);
        resolve(response);
      }).catch((error) => {
        window.deviseSettings.$bus.$emit('showError', error);
      });
    }).catch((error) => {
      window.deviseSettings.$bus.$emit('showError', error);
    });
  },

  updateSeason(context, payload) {
    return new Promise((resolve) => {
      window.axios.put(`${context.state.devise.api.baseUrl}seasons/${payload.season.id}`, payload.data).then((response) => {
        window.deviseSettings.$bus.$emit('showMessage', { title: 'Success!', message: `${payload.data.years} has been saved.` });
        context.commit('updateSeasons', { season: payload.season, data: response.data });
        resolve(response);
      }).catch((error) => {
        window.deviseSettings.$bus.$emit('showError', error);
      });
    }).catch((error) => {
      window.deviseSettings.$bus.$emit('showError', error);
    });
  },

  deleteSeason(context, season) {
    return new Promise((resolve) => {
      window.axios.delete(`${context.state.devise.api.baseUrl}seasons/${season.id}`).then((response) => {
        window.deviseSettings.$bus.$emit('showMessage', { title: 'Success!', message: `${season.years} has been deleted.` });
        context.commit('deleteSeasons', season);
        resolve(response);
      }).catch((error) => {
        window.deviseSettings.$bus.$emit('showError', error);
      });
    }).catch((error) => {
      window.deviseSettings.$bus.$emit('showError', error);
    });
  },
};

export default actions;
