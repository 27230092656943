<template>

  <div>

    <fieldset class="dvs-fieldset mb-8">
      <label>Name</label>
      <input
        type="text"
        v-model="value.name"
      >
    </fieldset>

    <fieldset class="dvs-fieldset mb-8">
      <label>Concert</label>
      <select v-model="value.concert_id">
        <option
          v-for="concert in concerts"
          :key="concert.id"
          :value="concert.id"
        >
          {{ concert.name }}
        </option>
      </select>
    </fieldset>

    <fieldset class="dvs-fieldset mb-8">
      <label>Location</label>
      <select v-model="value.location_id">
        <option
          v-for="location in locations"
          :key="location.id"
          :value="location.id"
        >{{ location.name }}</option>
      </select>
    </fieldset>

    <fieldset class="dvs-fieldset mb-8">
      <label>Date</label>
      <devise-datepicker
        v-model="value.date"
        :settings="{date:true}"
      />
    </fieldset>

    <fieldset class="dvs-fieldset mb-8">
      <label>Call Time</label>
      <devise-datepicker
        v-model="value.call_time"
        :settings="{time:true}"
      />
    </fieldset>

    <fieldset class="dvs-fieldset mb-8">
      <label>Downbeat</label>
      <devise-datepicker
        v-model="value.downbeat"
        :settings="{time:true}"
      />
    </fieldset>

  </div>

</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DeviseDatepicker from 'devisephp-interface/src/components/utilities/DatePicker.vue';

export default {
  name: 'RehearsalsForm',
  mounted() {
    this.getLocations();
    this.getAllConcerts();
  },
  methods: {
    ...mapActions([
      'getLocations',
      'getAllConcerts',
    ]),
    updateValue() {
      // Emit the number value through the input event
      this.$emit('input', this.localValue);
      this.$emit('change', this.localValue);
    },
  },
  computed: {
    ...mapGetters([
      'locations',
      'concerts',
    ]),
  },
  components: {
    DeviseDatepicker,
  },
  watch: {
    value() {
      this.updateValue();
    },
  },
  props: ['value'],
};
</script>
