import '@babel/polyfill';
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

// Devise
import Vue from 'vue';

import ArrowDropdownIcon from 'vue-ionicons/dist/md-arrow-dropdown.vue';
import CloseIcon from 'vue-ionicons/dist/ios-close.vue';
import FacebookIcon from 'vue-ionicons/dist/logo-facebook.vue';
import MenuIcon from 'vue-ionicons/dist/ios-menu.vue';
import PlayIcon from 'vue-ionicons/dist/ios-play.vue';
import PauseIcon from 'vue-ionicons/dist/ios-pause.vue';
import YouTubeIcon from 'vue-ionicons/dist/logo-youtube.vue';

// Devise requires a bus, vue-router and vuex. We initialize these in your application
// so that both apps can share the same store and router. All devise vuex
// is namespaced under the "devise" namespace.
import { sync } from 'vuex-router-sync';
import PortalVue from 'portal-vue';
import Devise from 'devisephp-interface';
import { mapGetters, mapActions } from 'vuex';
import EventBus from './event-bus';
import router from './router';
import store from './vuex/store';

require('./bootstrap');

window.bus = EventBus;
window.Vue = Vue;

sync(store, router);

// We initialize the Devise plugin and pass our router, store, and bus to share
// // these resources so that your application can tap into them.
Vue.use(Devise, {
  store,
  router,
  bus: window.bus,
  options: {
    adminClass: '',
  },
});
Vue.use(PortalVue);

// Components
Vue.component('audio-player', () => import(/* webpackChunkName: "app-ui" */ './components/ui/AudioPlayer.vue'));
Vue.component('calendar', () => import(/* webpackChunkName: "app-ui" */ './components/ui/Calendar.vue'));
Vue.component('concerts', () => import(/* webpackChunkName: "app-ui" */ './components/ui/Concerts.vue'));
Vue.component('corporate-sponsors', () => import(/* webpackChunkName: "app-ui" */ './components/sliders/CorporateSponsors.vue'));
Vue.component('donors', () => import(/* webpackChunkName: "app-ui" */ './components/ui/Donors.vue'));
Vue.component('hero', () => import(/* webpackChunkName: "app-ui" */ './components/sliders/Hero.vue'));
Vue.component('navigation', () => import(/* webpackChunkName: "app-ui" */ './components/ui/Navigation.vue'));
Vue.component('members', () => import(/* webpackChunkName: "app-ui" */ './components/ui/Members.vue'));
Vue.component('ga-phil-logo', () => import(/* webpackChunkName: "app-ui" */ './components/icons/GaPhilLogo.vue'));

// Icons
Vue.component('arrow-dropdown-icon', ArrowDropdownIcon);
Vue.component('close-icon', CloseIcon);
Vue.component('facebook-icon', FacebookIcon);
Vue.component('menu-icon', MenuIcon);
Vue.component('play-icon', PlayIcon);
Vue.component('pause-icon', PauseIcon);
Vue.component('youtube-icon', YouTubeIcon);

// eslint-disable-next-line no-unused-vars
const app = new Vue({
  el: '#app',
  router,

  mounted() {
    this.appLoaded();
  },
  methods: {
    ...mapActions([
      'getBrands',
    ]),
    appLoaded() {
      window.bus.$on('devise-loaded', () => {
      });
    },
  },
  computed: {
    ...mapGetters([
      'appState',
    ]),
    mobileMenuOpen() {
      return this.appState.mobileMenuOpen;
    },
  },
});
