<template>

  <div>

    <div id="devise-admin-content" v-if="RehearsalToCreate">

      <h2 class="dvs-mb-10" :style="{color: theme.panel.color}">Create Rehearsals: {{ RehearsalToCreate.name }}</h2>

      <Rehearsals-form v-model="RehearsalToCreate"></Rehearsals-form>

      <button class="dvs-btn" :style="theme.actionButton" @click="requestCreateRehearsals" :disabled="createInvalid">
        Create
      </button>
      <button class="dvs-btn" :style="theme.actionButtonGhost" @click="goToPage('devise-rehearsals-index')">Cancel
      </button>
    </div>

  </div>

</template>

<script>
  import {mapActions} from 'vuex'

  import RehearsalsForm from './Form'

  export default {
    name: 'RehearsalCreate',
    data() {
      return {
        RehearsalToCreate: {
          name: 'Rehearsal',
          concert_id: '',
          location_id: '',
          date: '',
          call_time: '',
          downbeat: ''
        }
      }
    },
    methods: {
      ...mapActions([
        'createRehearsal'
      ]),
      requestCreateRehearsals() {
        this.createRehearsal(this.RehearsalToCreate).then(() => {
          this.goToPage('devise-rehearsals-index')
        })
      }
    },
    computed: {
      createInvalid() {
        return this.RehearsalToCreate.name === null
      }
    },
    components: {
      RehearsalsForm
    }
  }
</script>
