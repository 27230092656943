<template>

  <div>

    <div id="devise-admin-content" v-if="editRehearsal">

      <h2 class="dvs-mb-10" :style="{color: theme.panel.color}">Editing Rehearsal: {{ editRehearsal.name }}</h2>

      <Rehearsal-form v-model="editRehearsal"></Rehearsal-form>

      <button class="dvs-btn" @click="requestEditRehearsal" :disabled="editInvalid" :style="theme.actionButton">Edit</button>
      <button class="dvs-btn" @click="goToPage('devise-rehearsals-index')" :style="theme.actionButtonGhost">Cancel</button>
    </div>

  </div>

</template>

<script>
import RehearsalForm from './Form'

import { mapActions, mapGetters } from 'vuex'
import dayjs from 'dayjs'

export default {
  name: 'RehearsalsEdit',
  data () {
    return {
      modulesToLoad: 1,
      editRehearsal: null
    }
  },
  mounted () {
    this.retrieveAllRehearsals()
  },
  methods: {
    ...mapActions([
      'getRehearsals',
      'updateRehearsal'
    ]),
    requestEditRehearsal () {
      this.updateRehearsal({Rehearsal: this.Rehearsal(this.$route.params.rehearsalId), data: this.editRehearsal}).then(() => {
        this.goToPage('devise-rehearsals-index')
      })
    },
    retrieveAllRehearsals (loadbar = true) {
      let self = this
      this.getRehearsals().then(function () {
        self.$set(self, 'editRehearsal', Object.assign({}, self.Rehearsal(self.$route.params.rehearsalId)))

        if (loadbar) {
          devise.$bus.$emit('incrementLoadbar', self.modulesToLoad)
        }
      })
    }
  },
  computed: {
    ...mapGetters([
      'Rehearsal'
    ]),
    editInvalid () {
      return this.editRehearsal.name === null ||
             this.editRehearsal.description === null
    }
  },
  components: {
    RehearsalForm
  }
}
</script>
