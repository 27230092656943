<template>

  <div>

    <div id="devise-admin-content" v-if="editPlayer">

      <h2 class="dvs-mb-10" :style="{color: theme.panel.color}">Editing Player: {{ editPlayer.name }}</h2>

      <player-form v-model="editPlayer"></player-form>

      <button class="dvs-btn" @click="requestEditPlayer" :disabled="editInvalid" :style="theme.actionButton">Edit</button>
      <button class="dvs-btn" @click="goToPage('devise-players-index')" :style="theme.actionButtonGhost">Cancel</button>
    </div>

  </div>

</template>

<script>
import PlayerForm from './Form'

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'PlayersEdit',
  data () {
    return {
      modulesToLoad: 1,
      editPlayer: null
    }
  },
  mounted () {
    this.retrieveAllPlayers()
  },
  methods: {
    ...mapActions([
      'getPlayers',
      'updatePlayer'
    ]),
    requestEditPlayer () {
      this.updatePlayer({player: this.player(this.$route.params.playerId), data: this.editPlayer}).then(() => {
        this.goToPage('devise-players-index')
      })
    },
    retrieveAllPlayers (loadbar = true) {
      let self = this
      this.getPlayers().then(function () {
        self.$set(self, 'editPlayer', Object.assign({}, self.player(self.$route.params.playerId)))

        if (loadbar) {
          devise.$bus.$emit('incrementLoadbar', self.modulesToLoad)
        }
      })
    }
  },
  computed: {
    ...mapGetters([
      'player'
    ]),
    editInvalid () {
      return this.editPlayer.name === null ||
             this.editPlayer.description === null
    }
  },
  components: {
    PlayerForm
  }
}
</script>
