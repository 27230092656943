export default {
  // Calendar Events

  setCalendarEvents(state, payload) {
    state.calendarEvents = payload
  },

  // Concerts
  createConcerts(state, concert) {
    state.concerts.push(concert)
  },

  updateConcerts(state, concert) {
    state.concerts.push(concert)
  },

  setConcerts(state, payload) {
    state.concerts = payload
  },

  deleteConcert(state, concert) {
    state.concerts.splice(state.concerts.indexOf(concert), 1)
  },

  updateConcert(state, {concert, data}) {
    concert = data
  },

  // Donors
  createDonors(state, donor) {
    state.donors.push(donor)
  },

  updateDonors(state, donor) {
    state.donors.push(donor)
  },

  setDonors(state, payload) {
    state.donors = payload
  },

  deleteDonor(state, donor) {
    state.donors.splice(state.donors.indexOf(donor), 1)
  },

  updateDonor(state, {donor, data}) {
    donor = data
  },


  // DonorLevels
  createDonorLevels(state, donorLevel) {
    state.donorLevels.push(donorLevel)
  },

  updateDonorLevels(state, donorLevel) {
    state.donorLevels.push(donorLevel)
  },

  setDonorLevels(state, payload) {
    state.donorLevels = payload
  },

  deleteDonorLevel(state, donorLevel) {
    state.donorLevels.splice(state.donorLevels.indexOf(donorLevel), 1)
  },

  updateDonorLevel(state, {donorLevel, data}) {
    donorLevel = data
  },

  // Locations
  createLocations(state, location) {
    state.locations.push(location)
  },

  setLocations(state, payload) {
    state.locations = payload
  },

  setFutureLocations(state, payload) {
    state.futureLocations = payload
  },

  deleteLocations(state, season) {
    state.seasons.splice(state.seasons.indexOf(season), 1)
  },

  updateLocations(state, {location, data}) {
    location = data
  },

  // Players
  createPlayers(state, player) {
    state.players.push(player)
  },

  setPlayers(state, payload) {
    state.players = payload
  },

  deletePlayers(state, season) {
    state.seasons.splice(state.seasons.indexOf(season), 1)
  },

  updatePlayers(state, {player, data}) {
    player = data
  },

// Rehearsals
  createRehearsals(state, Rehearsal) {
    state.Rehearsals.push(Rehearsal)
  },

  setRehearsals(state, payload) {
    state.Rehearsals = payload
  },

  deleteRehearsals(state, season) {
    state.seasons.splice(state.seasons.indexOf(season), 1)
  },

  updateRehearsals(state, {Rehearsal, data}) {
    Rehearsal = data
  },

  // Seasons
  createSeasons(state, season) {
    state.seasons.push(season)
  },

  setSeasons(state, payload) {
    state.seasons = payload
  },

  deleteSeasons(state, season) {
    state.seasons.splice(state.seasons.indexOf(season), 1)
  },

  updateSeasons(state, {season, data}) {
    season = data
  },
}
