import Vue from 'vue';
import VueRouter from 'vue-router';

import ConcertsIndex from './components/devise-admin/concerts/Index.vue';
import ConcertsEdit from './components/devise-admin/concerts/Edit.vue';
import ConcertsCreate from './components/devise-admin/concerts/Create.vue';

import DonorLevelsIndex from './components/devise-admin/donor_levels/Index.vue';
import DonorLevelsEdit from './components/devise-admin/donor_levels/Edit.vue';
import DonorLevelsCreate from './components/devise-admin/donor_levels/Create.vue';

import DonorsIndex from './components/devise-admin/donors/Index.vue';
import DonorsEdit from './components/devise-admin/donors/Edit.vue';
import DonorsCreate from './components/devise-admin/donors/Create.vue';

import LocationsIndex from './components/devise-admin/locations/Index.vue';
import LocationsEdit from './components/devise-admin/locations/Edit.vue';
import LocationsCreate from './components/devise-admin/locations/Create.vue';

import PlayersIndex from './components/devise-admin/players/Index.vue';
import PlayersEdit from './components/devise-admin/players/Edit.vue';
import PlayersCreate from './components/devise-admin/players/Create.vue';

import RehearsalsIndex from './components/devise-admin/rehearsals/Index.vue';
import RehearsalsEdit from './components/devise-admin/rehearsals/Edit.vue';
import RehearsalsCreate from './components/devise-admin/rehearsals/Create.vue';

import SeasonsIndex from './components/devise-admin/seasons/Index.vue';
import SeasonsEdit from './components/devise-admin/seasons/Edit.vue';
import SeasonsCreate from './components/devise-admin/seasons/Create.vue';

const routes = [
  // Concerts
  {
    path: '/devise/concerts',
    name: 'devise-concerts-index',
    components: {
      devise: ConcertsIndex,
    },
    meta: {
      title: 'Manage Concerts',
      parentRouteName: 'devise-models',
    },
  },
  {
    path: '/devise/concerts/:concertId/edit',
    name: 'devise-concerts-edit',
    components: {
      devise: ConcertsEdit,
    },
    meta: {
      title: 'Edit Concert',
      parentRouteName: 'devise-models',
    },
  },
  {
    path: '/devise/concerts/create',
    name: 'devise-concerts-create',
    components: {
      devise: ConcertsCreate,
    },
    meta: {
      title: 'Create New Concert',
      parentRouteName: 'devise-models',
    },
  },

  // Donor Levels
  {
    path: '/devise/donor-levels',
    name: 'devise-donor-levels-index',
    components: {
      devise: DonorLevelsIndex,
    },
    meta: {
      title: 'Manage DonorLevels',
      parentRouteName: 'devise-models',
    },
  },
  {
    path: '/devise/donor-levels/:donor-levelId/edit',
    name: 'devise-donor-levels-edit',
    components: {
      devise: DonorLevelsEdit,
    },
    meta: {
      title: 'Edit DonorLevel',
      parentRouteName: 'devise-models',
    },
  },
  {
    path: '/devise/donor-levels/create',
    name: 'devise-donor-levels-create',
    components: {
      devise: DonorLevelsCreate,
    },
    meta: {
      title: 'Create New DonorLevel',
      parentRouteName: 'devise-models',
    },
  },

  // Donors
  {
    path: '/devise/donors',
    name: 'devise-donors-index',
    components: {
      devise: DonorsIndex,
    },
    meta: {
      title: 'Manage Donors',
      parentRouteName: 'devise-models',
    },
  },
  {
    path: '/devise/donors/:donorId/edit',
    name: 'devise-donors-edit',
    components: {
      devise: DonorsEdit,
    },
    meta: {
      title: 'Edit Donor',
      parentRouteName: 'devise-models',
    },
  },
  {
    path: '/devise/donors/create',
    name: 'devise-donors-create',
    components: {
      devise: DonorsCreate,
    },
    meta: {
      title: 'Create New Donor',
      parentRouteName: 'devise-models',
    },
  },

  // Locations
  {
    path: '/devise/locations',
    name: 'devise-locations-index',
    components: {
      devise: LocationsIndex,
    },
    meta: {
      title: 'Manage Locations',
      parentRouteName: 'devise-models',
    },
  },
  {
    path: '/devise/locations/:locationId/edit',
    name: 'devise-locations-edit',
    components: {
      devise: LocationsEdit,
    },
    meta: {
      title: 'Edit Location',
      parentRouteName: 'devise-models',
    },
  },
  {
    path: '/devise/locations/create',
    name: 'devise-locations-create',
    components: {
      devise: LocationsCreate,
    },
    meta: {
      title: 'Create New Location',
      parentRouteName: 'devise-models',
    },
  },


  // Players
  {
    path: '/devise/players',
    name: 'devise-players-index',
    components: {
      devise: PlayersIndex,
    },
    meta: {
      title: 'Manage Players',
      parentRouteName: 'devise-models',
    },
  },
  {
    path: '/devise/players/:playerId/edit',
    name: 'devise-players-edit',
    components: {
      devise: PlayersEdit,
    },
    meta: {
      title: 'Edit Player',
      parentRouteName: 'devise-models',
    },
  },
  {
    path: '/devise/players/create',
    name: 'devise-players-create',
    components: {
      devise: PlayersCreate,
    },
    meta: {
      title: 'Create New Player',
      parentRouteName: 'devise-models',
    },
  },

  // Rehearsals
  {
    path: '/devise/rehearsals',
    name: 'devise-rehearsals-index',
    components: {
      devise: RehearsalsIndex,
    },
    meta: {
      title: 'Manage Rehearsals',
      parentRouteName: 'devise-models',
    },
  },
  {
    path: '/devise/rehearsals/:rehearsalId/edit',
    name: 'devise-rehearsals-edit',
    components: {
      devise: RehearsalsEdit,
    },
    meta: {
      title: 'Edit Rehearsal',
      parentRouteName: 'devise-models',
    },
  },
  {
    path: '/devise/rehearsals/create',
    name: 'devise-rehearsals-create',
    components: {
      devise: RehearsalsCreate,
    },
    meta: {
      title: 'Create New Rehearsal',
      parentRouteName: 'devise-models',
    },
  },

  // Seasons
  {
    path: '/devise/seasons',
    name: 'devise-seasons-index',
    components: {
      devise: SeasonsIndex,
    },
    meta: {
      title: 'Manage Seasons',
      parentRouteName: 'devise-models',
    },
  },
  {
    path: '/devise/seasons/:seasonId/edit',
    name: 'devise-seasons-edit',
    components: {
      devise: SeasonsEdit,
    },
    meta: {
      title: 'Edit Season',
      parentRouteName: 'devise-models',
    },
  },
  {
    path: '/devise/seasons/create',
    name: 'devise-seasons-create',
    components: {
      devise: SeasonsCreate,
    },
    meta: {
      title: 'Create New Season',
      parentRouteName: 'devise-models',
    },
  },
];

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'abstract',
  transitionOnLoad: true,
  routes,
});

export default router;
