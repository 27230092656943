<template>

  <div>
    
    <div id="devise-admin-content" v-if="donorLevelToCreate">

      <h2 class="dvs-mb-10" :style="{color: theme.panel.color}">Create DonorLevels: {{ donorLevelToCreate.name }}</h2>

      <donor-levels-form v-model="donorLevelToCreate"></donor-levels-form>

      <button class="dvs-btn" :style="theme.actionButton" @click="requestCreateDonorLevels" :disabled="createInvalid">
        Create
      </button>
      <button class="dvs-btn" :style="theme.actionButtonGhost" @click="goToPage('devise-donor-level-index')">Cancel</button>
    </div>

  </div>

</template>

<script>
  import {mapActions} from 'vuex'
  
  import DonorLevelsForm from './Form'
  
  export default {
    name: 'DonorLevelCreate',
    data() {
      return {
        donorLevelToCreate: {
          label: '',
          range: '',
          sort: ''
        }
      }
    },
    methods: {
      ...mapActions([
        'createDonorLevel'
      ]),
      requestCreateDonorLevels() {
        this.createDonorLevel(this.donorLevelToCreate).then(() => {
          this.goToPage('devise-donor-levels-index')
        })
      }
    },
    computed: {
      createInvalid() {
        return this.donorLevelToCreate.name === null
      }
    },
    components: {
      DonorLevelsForm
    }
  }
</script>
