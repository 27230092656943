import Vue from 'vue'
import Vuex from 'vuex'
import mutations from './mutations'
import actions from './actions'
import getters from './getters'

Vue.use(Vuex)

function insertIf(condition, ...elements) { // (A)
  return condition ? elements : [];
}

// root state object.
// each Vuex instance is just a single state tree.
const state = {
  adminMenu: [
    {
      icon: 'CreateIcon',
      routeName: 'devise-page-editor'
    },
    ... typeof deviseSettings.$page === 'undefined' ? () => null : [{
      icon: 'DocumentIcon',
      routeName: 'devise-pages-view',
      routeParams: {
        pageId: deviseSettings.$page.id
      }
    }],
    {
      icon: 'CubeIcon',
      label: 'Site Data',
      routeName: 'devise-models',
      permissions: 'manage settings',
      menu: [
        {
          label: 'Seasons',
          routeName: 'devise-seasons-index',
        },
        {
          label: 'Concerts',
          routeName: 'devise-concerts-index',
        },
        {
          label: 'Rehearsals',
          routeName: 'devise-rehearsals-index',
        },
        {
          label: 'Players',
          routeName: 'devise-players-index',
        },
        {
          label: 'Locations',
          routeName: 'devise-locations-index',
        },
        {
          label: 'Donors',
          routeName: 'devise-donors-index',
        },
        {
          label: 'Donor Levels',
          routeName: 'devise-donor-levels-index',
        },
      ]
    },
    {
      icon: 'ImageIcon',
      label: 'Media',
      routeName: 'media-manager',
      permissions: 'manage media',
    },
    {
      icon: 'CogIcon',
      label: 'Settings',
      routeName: 'devise-settings',
      permissions: 'manage settings',
      menu: [
        {
          label: 'Pages',
          routeName: 'devise-pages-index',
          permissions: 'manage pages',
        },
        {
          label: 'Users',
          routeName: 'devise-users-index',
          permissions: 'manage users',
        },
        {
          label: 'Mothership',
          routeName: 'devise-mothership-index',
          permissions: 'manage mothership',
        },
        {
          label: 'Global Meta',
          routeName: 'devise-meta-manage',
          permissions: 'manage meta',
        },
        {
          label: 'Sites',
          routeName: 'devise-sites-index',
          permissions: 'manage sites',
        },
        {
          label: 'Languages',
          routeName: 'devise-languages-manage',
          permissions: 'manage languages',
        },
        {
          label: 'Redirects',
          routeName: 'devise-redirects-index',
          permissions: 'manage redirects',
        }
      ]
    }
  ],
  calendarEvents: [],
  concerts: [],
  donors: [],
  donorLevels: [],
  locations: [],
  futureLocations: [],
  players: [],
  Rehearsals: [],
  seasons: [],
}

// A Vuex instance is created by combining the state, the actions,
// and the mutations. Because the actions and mutations are just
// functions that do not depend on the instance itself, they can
// be easily tested or even hot-reloaded (see counter-hot example).
//
// You can also provide middlewares, which is just an array of
// objects containing some hooks to be called at initialization
// and after each mutation.
export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters
})
