<template>

  <div>
    <div id="devise-admin-content">
      <action-bar>
        <li class="dvs-btn dvs-btn-sm dvs-mb-2" :style="theme.actionButton" @click.prevent="showCreateDonorLevel">
          Create New Donor Level
        </li>
      </action-bar>

      <h2 class="dvs-mb-10" :style="{color: theme.panel.color}">Current Donor Levels</h2>

      <div v-for="donorLevel in donorLevels" :key="donorLevel.id" class="dvs-mb-6 dvs-flex dvs-justify-between dvs-items-center">
        <div class="dvs-min-w-2/5 dvs-text-base dvs-font-bold dvs-pr-8">
          {{ donorLevel.label }}<br>
          <span class="dvs-font-normal dvs-text-xs">Appearance Order: {{ donorLevel.sort }}</span>
        </div>
        <div class="dvs-w-2/5 dvs-px-8 dvs-flex dvs-justify-end">
          <button class="dvs-btn dvs-btn-xs dvs-mr-2" @click="showEditDonorLevel(donorLevel)" :style="theme.actionButtonGhost">Edit</button>
          <button class="dvs-btn dvs-btn-xs" v-devise-alert-confirm="{callback: requestDeleteDonorLevel, arguments: donorLevel, message: 'Are you sure you want to delete this donorLevel?'}" :style="theme.actionButtonGhost">Delete</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'DonorLevelsIndex',
  data () {
    return {
      modulesToLoad: 1
    }
  },
  mounted () {
    this.retrieveAllDonorLevels()
  },
  watch: {
    language() {
      this.retrieveAllDonorLevels();
    }
  },
  methods: {
    ...mapActions([
      'getDonorLevels',
      'deleteDonorLevel'
    ]),
    showEditDonorLevel (donorLevel)  {
      this.$router.push({name: 'devise-donor-levels-edit', params: {donorLevelId: donorLevel.id}})
    },
    showCreateDonorLevel (donorLevel)  {
      this.$router.push({name: 'devise-donor-levels-create'})
    },
    requestDeleteDonorLevel (donorLevel) {
      let self = this
      this.deleteDonorLevel(donorLevel).then(function () {
        self.retrieveAllDonorLevels()
      })
    },
    retrieveAllDonorLevels (loadbar = true) {
      this.getDonorLevels().then(function () {
        if (loadbar) {
          devise.$bus.$emit('incrementLoadbar', self.modulesToLoad)
        }
      })
    },
    originalDonorLevel(id) {
      return this.donorLevels.data.find(donorLevel => donorLevel.id === id)
    }
  },
  computed: {
    ...mapGetters([
      'donorLevels'
    ])
  }
}
</script>
