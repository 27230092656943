<template>

  <div>

    <fieldset class="dvs-fieldset mb-8">
      <label>Years</label>
      <input
        type="text"
        v-model="value.years"
        placeholder="Name of the Season"
      >
    </fieldset>

    <fieldset class="dvs-fieldset mb-8">
      <label>Description</label>
      <devise-wysiwyg v-model="value.description"></devise-wysiwyg>
    </fieldset>

  </div>

</template>

<script>
import DeviseWysiwyg from 'devisephp-interface/src/components/utilities/Wysiwyg.vue';

export default {
  name: 'SeasonsForm',
  methods: {
    updateValue() {
      // Emit the number value through the input event
      this.$emit('input', this.localValue);
      this.$emit('change', this.localValue);
    },
  },
  components: {
    DeviseWysiwyg,
  },
  watch: {
    value() {
      this.updateValue();
    },
  },
  props: ['value'],
};
</script>
