<template>

  <div>

    <div id="devise-admin-content" v-if="playerToCreate">

      <h2 class="dvs-mb-10" :style="{color: theme.panel.color}">New Player: {{ playerToCreate.name }}</h2>

      <players-form v-model="playerToCreate"></players-form>

      <button class="dvs-btn" :style="theme.actionButton" @click="requestCreatePlayers" :disabled="createInvalid">
        Create
      </button>
      <button class="dvs-btn" :style="theme.actionButtonGhost" @click="goToPage('devise-players-index')">Cancel</button>
    </div>

  </div>

</template>

<script>
  import {mapActions} from 'vuex'

  import PlayersForm from './Form'

  export default {
    name: 'PlayerCreate',
    data() {
      return {
        playerToCreate: {
          position: 0,
          section: '',
          subsection: '',
          name: '',
          title: '',
          bio: '',
          image: ''
        }
      }
    },
    methods: {
      ...mapActions([
        'createPlayer'
      ]),
      requestCreatePlayers() {
        this.createPlayer(this.playerToCreate).then(() => {
          this.goToPage('devise-players-index')
        })
      }
    },
    computed: {
      createInvalid() {
        return this.playerToCreate.name === null
      }
    },
    components: {
      PlayersForm
    }
  }
</script>
