<template>

  <div>
    <div id="devise-admin-content">
      <action-bar>
        <li class="dvs-btn dvs-btn-sm dvs-mb-2" :style="theme.actionButton" @click.prevent="showCreateSeason">
          Create New Season
        </li>
      </action-bar>

      <h2 class="dvs-mb-10" :style="{color: theme.panel.color}">Current Seasons</h2>

      <div v-for="season in seasons" :key="season.id" class="dvs-mb-6 dvs-flex dvs-justify-between dvs-items-center">
        <div class="dvs-min-w-2/5 dvs-text-base dvs-font-bold dvs-pr-8">
          {{ season.years }}<br>
        </div>
        <div class="dvs-w-2/5 dvs-px-8 dvs-flex dvs-justify-end">
          <button class="dvs-btn dvs-btn-xs dvs-mr-2" @click="showEditSeason(season)" :style="theme.actionButtonGhost">Edit</button>
          <button class="dvs-btn dvs-btn-xs" v-devise-alert-confirm="{callback: requestDeleteSeason, arguments: season, message: 'Are you sure you want to delete this season?'}" :style="theme.actionButtonGhost">Delete</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SeasonsIndex',
  data () {
    return {
      modulesToLoad: 1
    }
  },
  mounted () {
    this.retrieveAllSeasons()
  },
  watch: {
    language() {
      this.retrieveAllSeasons();
    }
  },
  methods: {
    ...mapActions([
      'getSeasons',
      'deleteSeason'
    ]),
    showEditSeason (season)  {
      this.$router.push({name: 'devise-seasons-edit', params: {seasonId: season.id}})
    },
    showCreateSeason (season)  {
      this.$router.push({name: 'devise-seasons-create'})
    },
    requestDeleteSeason (season) {
      let self = this
      this.deleteSeason(season).then(function () {
        self.retrieveAllSeasons()
      })
    },
    retrieveAllSeasons (loadbar = true) {
      this.getSeasons().then(function () {
        if (loadbar) {
          devise.$bus.$emit('incrementLoadbar', self.modulesToLoad)
        }
      })
    },
    originalSeason(id) {
      return this.seasons.data.find(season => season.id === id)
    }
  },
  computed: {
    ...mapGetters([
      'seasons'
    ])
  }
}
</script>
