<template>

  <div>

    <fieldset class="dvs-fieldset mb-8">
      <label>Name</label>
      <input
        type="text"
        v-model="value.name"
        placeholder="Name of the Location"
      >
    </fieldset>

    <fieldset class="dvs-fieldset mb-8">
      <label>Street</label>
      <input
        type="text"
        v-model="value.street"
        placeholder="Street of the Location"
      >
    </fieldset>

    <fieldset class="dvs-fieldset mb-8">
      <label>City</label>
      <input
        type="text"
        v-model="value.city"
        placeholder="City of the Location"
      >
    </fieldset>

    <fieldset class="dvs-fieldset mb-8">
      <label>State</label>
      <input
        type="text"
        v-model="value.state"
        placeholder="State of the Location"
      >
    </fieldset>

    <fieldset class="dvs-fieldset mb-8">
      <label>Zipcode</label>
      <input
        type="text"
        v-model="value.zipcode"
        placeholder="Zipcode of the Location"
      >
    </fieldset>

    <fieldset class="dvs-fieldset mb-8">
      <label>Phone</label>
      <input
        type="text"
        v-model="value.phone"
        placeholder="Phone of the Location"
      >
    </fieldset>

    <fieldset class="dvs-fieldset mb-8">
      <label>Website</label>
      <input
        type="text"
        v-model="value.website"
        placeholder="Website of the Location"
      >
    </fieldset>

    <fieldset class="dvs-fieldset mb-8">
      <label>Map URL</label>
      <input
        type="text"
        v-model="value.map_link"
        placeholder="Map URL of the Location"
      >
    </fieldset>

    <fieldset class="dvs-fieldset mb-8">
      <label>Notes</label>
      <textarea
        v-model="value.notes"
        placeholder="Notes of the Location"
      ></textarea>
    </fieldset>

  </div>

</template>

<script>
export default {
  name: 'LocationsForm',
  methods: {
    updateValue() {
      // Emit the number value through the input event
      this.$emit('input', this.localValue);
      this.$emit('change', this.localValue);
    },
  },
  watch: {
    value() {
      this.updateValue();
    },
  },
  props: ['value'],
};
</script>
