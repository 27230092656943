<template>

  <div>

    <div id="devise-admin-content" v-if="editSeason">

      <h2 class="dvs-mb-10" :style="{color: theme.panel.color}">Editing Season: {{ editSeason.name }}</h2>

      <season-form v-model="editSeason"></season-form>

      <button class="dvs-btn" @click="requestEditSeason" :disabled="editInvalid" :style="theme.actionButton">Edit</button>
      <button class="dvs-btn" @click="goToPage('devise-seasons-index')" :style="theme.actionButtonGhost">Cancel</button>
    </div>

  </div>

</template>

<script>
import SeasonForm from './Form'

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SeasonsEdit',
  data () {
    return {
      modulesToLoad: 1,
      editSeason: null
    }
  },
  mounted () {
    this.retrieveAllSeasons()
  },
  methods: {
    ...mapActions([
      'getSeasons',
      'updateSeason'
    ]),
    requestEditSeason () {
      this.updateSeason({season: this.season(this.$route.params.seasonId), data: this.editSeason}).then(() => {
        this.goToPage('devise-seasons-index')
      })
    },
    retrieveAllSeasons (loadbar = true) {
      let self = this
      this.getSeasons().then(function () {
        self.$set(self, 'editSeason', Object.assign({}, self.season(self.$route.params.seasonId)))

        if (loadbar) {
          devise.$bus.$emit('incrementLoadbar', self.modulesToLoad)
        }
      })
    }
  },
  computed: {
    ...mapGetters([
      'season'
    ]),
    editInvalid () {
      return this.editSeason.name === null ||
             this.editSeason.description === null
    }
  },
  components: {
    SeasonForm
  }
}
</script>
