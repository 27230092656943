<template>

  <div>
    <div id="devise-admin-content">
      <action-bar>
        <li class="dvs-btn dvs-btn-sm dvs-mb-2" :style="theme.actionButton" @click.prevent="showCreateRehearsal">
          Create New Rehearsal
        </li>
      </action-bar>

      <h2 class="dvs-mb-10" :style="{color: theme.panel.color}">Rehearsals</h2>

      <div v-for="Rehearsal in Rehearsals" :key="Rehearsal.id" class="dvs-mb-6 dvs-flex dvs-justify-between dvs-items-center">
        <div class="dvs-min-w-2/5 dvs-text-base dvs-font-bold dvs-pr-8">
          {{ getFormattedDate(Rehearsal.date) }}<br>
          <span class="dvs-font-normal dvs-text-xs" v-if="Rehearsal.call_time !== '' && Rehearsal.call_time !== null">{{ getFormattedTime(Rehearsal.call_time) }} Call Time</span>
          <span class="dvs-font-normal dvs-text-xs" v-else>TBD</span>
        </div>
        <div class="dvs-w-2/5 dvs-px-8 dvs-flex dvs-justify-end">
          <button class="dvs-btn dvs-btn-xs dvs-mr-2" @click="showEditRehearsal(Rehearsal)" :style="theme.actionButtonGhost">Edit</button>
          <button class="dvs-btn dvs-btn-xs" v-devise-alert-confirm="{callback: requestDeleteRehearsal, arguments: Rehearsal, message: 'Are you sure you want to delete this Rehearsal?'}" :style="theme.actionButtonGhost">Delete</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import dayjs from 'dayjs'

export default {
  name: 'RehearsalsIndex',
  data () {
    return {
      modulesToLoad: 1
    }
  },
  mounted () {
    this.retrieveAllRehearsals()
  },
  watch: {
    language() {
      this.retrieveAllRehearsals();
    }
  },
  methods: {
    ...mapActions([
      'getRehearsals',
      'deleteRehearsal'
    ]),
    showEditRehearsal (Rehearsal)  {
      this.$router.push({name: 'devise-rehearsals-edit', params: {rehearsalId: Rehearsal.id}})
    },
    showCreateRehearsal (Rehearsal)  {
      this.$router.push({name: 'devise-rehearsals-create'})
    },
    requestDeleteRehearsal (Rehearsal) {
      let self = this
      this.deleteRehearsal(Rehearsal).then(function () {
        self.retrieveAllRehearsals()
      })
    },
    retrieveAllRehearsals (loadbar = true) {
      this.getRehearsals().then(function () {
        if (loadbar) {
          devise.$bus.$emit('incrementLoadbar', self.modulesToLoad)
        }
      })
    },
    originalRehearsal(id) {
      return this.Rehearsals.data.find(Rehearsal => Rehearsal.id === id)
    },
    getFormattedDate(date) {
      return dayjs(date).format('MMMM DD, YYYY')
    },
    getFormattedTime(time) {
      return dayjs(`12/27/1980 ${time}`).format('h:mm A')
    }
  },
  computed: {
    ...mapGetters([
      'Rehearsals'
    ])
  }
}
</script>
