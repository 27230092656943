<template>

  <div>

    <div id="devise-admin-content" v-if="concertToCreate">

      <h2 class="dvs-mb-10" :style="{color: theme.panel.color}">Create Concerts: {{ concertToCreate.name }}</h2>

      <concerts-form v-model="concertToCreate"></concerts-form>

      <button class="dvs-btn" :style="theme.actionButton" @click="requestCreateConcerts" :disabled="createInvalid">
        Create
      </button>
      <button class="dvs-btn" :style="theme.actionButtonGhost" @click="goToPage('devise-concerts-index')">Cancel</button>
    </div>

  </div>

</template>

<script>
  import {mapActions} from 'vuex'

  import ConcertsForm from './Form'

  export default {
    name: 'ConcertCreate',
    data() {
      return {
        concertToCreate: {
          location_id: 0,
          season_id: 0,
          name: '',
          image: '',
          date: '',
          time_tbd: false,
          description: '',
          secondary_group: '',
          tickets_link: '',
          is_subscription: true,
          page_details: '',
          page_slug: ''
        }
      }
    },
    methods: {
      ...mapActions([
        'createConcert'
      ]),
      requestCreateConcerts() {
        this.createConcert(this.concertToCreate).then(() => {
          this.goToPage('devise-concerts-index')
        })
      }
    },
    computed: {
      createInvalid() {
        return this.concertToCreate.name === null
      }
    },
    components: {
      ConcertsForm
    }
  }
</script>
