<template>

  <div>
    <div id="devise-admin-content">
      <action-bar>
        <li class="dvs-btn dvs-btn-sm dvs-mb-2" :style="theme.actionButton" @click.prevent="showCreateConcert">
          Create New Concert
        </li>
      </action-bar>

      <h2 class="dvs-mb-10" :style="{color: theme.panel.color}">Current Concerts</h2>

      <div v-for="concert in concerts" :key="concert.id" class="dvs-mb-6 dvs-flex dvs-justify-between dvs-items-center">
        <div class="dvs-min-w-2/5 dvs-text-base dvs-font-bold dvs-pr-8">
          {{ concert.name }}<br>
          <span class="dvs-font-normal dvs-text-xs" v-if="concert.season">{{ concert.season.years }} Season</span>
        </div>
        <div class="dvs-w-2/5 dvs-px-8 dvs-flex dvs-justify-end">
          <button class="dvs-btn dvs-btn-xs dvs-mr-2" @click="showEditConcert(concert)" :style="theme.actionButtonGhost">Edit</button>
          <button class="dvs-btn dvs-btn-xs" v-devise-alert-confirm="{callback: requestDeleteConcert, arguments: concert, message: 'Are you sure you want to delete this concert?'}" :style="theme.actionButtonGhost">Delete</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ConcertsIndex',
  data () {
    return {
      modulesToLoad: 1
    }
  },
  mounted () {
    this.retrieveAllConcerts()
  },
  watch: {
    language() {
      this.retrieveAllConcerts();
    }
  },
  methods: {
    ...mapActions([
      'getAllConcerts',
      'deleteConcert'
    ]),
    showEditConcert (concert)  {
      this.$router.push({name: 'devise-concerts-edit', params: {concertId: concert.id}})
    },
    showCreateConcert (concert)  {
      this.$router.push({name: 'devise-concerts-create'})
    },
    requestDeleteConcert (concert) {
      let self = this
      this.deleteConcert(concert).then(function () {
        self.retrieveAllConcerts()
      })
    },
    retrieveAllConcerts (loadbar = true) {
      this.getAllConcerts().then(function () {
        if (loadbar) {
          devise.$bus.$emit('incrementLoadbar', self.modulesToLoad)
        }
      })
    },
    originalConcert(id) {
      return this.concerts.data.find(concert => concert.id === id)
    }
  },
  computed: {
    ...mapGetters([
      'concerts'
    ])
  }
}
</script>
