<template>

  <div>

    <div id="devise-admin-content" v-if="editConcert">

      <h2 class="dvs-mb-10" :style="{color: theme.panel.color}">Editing Concert: {{ editConcert.name }}</h2>

      <concert-form v-model="editConcert"></concert-form>

      <button class="dvs-btn" @click="requestEditConcert" :disabled="editInvalid" :style="theme.actionButton">Edit</button>
      <button class="dvs-btn" @click="goToPage('devise-concerts-index')" :style="theme.actionButtonGhost">Cancel</button>
    </div>

  </div>

</template>

<script>
import ConcertForm from './Form'

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ConcertsEdit',
  data () {
    return {
      modulesToLoad: 1,
      editConcert: null
    }
  },
  mounted () {
    this.retrieveAllConcerts()
  },
  methods: {
    ...mapActions([
      'getAllConcerts',
      'updateConcert'
    ]),
    requestEditConcert () {
      this.updateConcert({concert: this.concert(this.$route.params.concertId), data: this.editConcert}).then(() => {
        this.goToPage('devise-concerts-index')
      })
    },
    retrieveAllConcerts (loadbar = true) {
      let self = this
      this.getAllConcerts().then(function () {
        self.$set(self, 'editConcert', Object.assign({}, self.concert(self.$route.params.concertId)))

        if (loadbar) {
          devise.$bus.$emit('incrementLoadbar', self.modulesToLoad)
        }
      })
    }
  },
  computed: {
    ...mapGetters([
      'concert'
    ]),
    editInvalid () {
      return this.editConcert.name === null
    }
  },
  components: {
    ConcertForm
  }
}
</script>
