<template>

  <div>
    
    <div id="devise-admin-content" v-if="locationToCreate">

      <h2 class="dvs-mb-10" :style="{color: theme.panel.color}">Create Locations: {{ locationToCreate.name }}</h2>

      <locations-form v-model="locationToCreate"></locations-form>

      <button class="dvs-btn" :style="theme.actionButton" @click="requestCreateLocations" :disabled="createInvalid">
        Create
      </button>
      <button class="dvs-btn" :style="theme.actionButtonGhost" @click="goToPage('devise-locations-index')">Cancel</button>
    </div>

  </div>

</template>

<script>
  import {mapActions} from 'vuex'
  
  import LocationsForm from './Form'
  
  export default {
    name: 'LocationCreate',
    data() {
      return {
        locationToCreate: {
          name: '',
          street: '',
          city: '',
          state: '',
          zipcode: '',
          phone: '',
          website: '',
          map_link: '',
          notes: '',
        }
      }
    },
    methods: {
      ...mapActions([
        'createLocation'
      ]),
      requestCreateLocations() {
        this.createLocation(this.locationToCreate).then(() => {
          this.goToPage('devise-locations-index')
        })
      }
    },
    computed: {
      createInvalid() {
        return this.locationToCreate.name === null
      }
    },
    components: {
      LocationsForm
    }
  }
</script>
