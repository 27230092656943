const getters = {
  // Concerts
  calendarEvents: state => {
    return state.calendarEvents
  },

  // Concerts
  concerts: state => {
    return state.concerts
  },
  concert: (state) => (id) => {
    return state.concerts.find(concert => concert.id === parseInt(id))
  },

  // Donors
  donors: state => {
    return state.donors
  },
  donor: (state) => (id) => {
    return state.donors.find(donor => donor.id === parseInt(id))
  },

  // Donor Levels
  donorLevels: state => {
    return state.donorLevels
  },
  donorLevel: (state) => (id) => {
    return state.donorLevels.find(donorLevel => donorLevel.id === parseInt(id))
  },

  // Locations
  locations: state => {
    return state.locations
  },
  futureLocations: state => {
    return state.futureLocations
  },
  location: (state) => (id) => {
    return state.locations.find(location => location.id === parseInt(id))
  },

  // Players
  players: state => {
    return state.players
  },
  player: (state) => (id) => {
    return state.players.find(player => player.id === parseInt(id))
  },

  // Rehearsals
  Rehearsals: state => {
    return state.Rehearsals
  },
  Rehearsal: (state) => (id) => {
    return state.Rehearsals.find(Rehearsal => Rehearsal.id === parseInt(id))
  },
  

  // Seasons
  seasons: state => {
    return state.seasons
  },
  season: (state) => (id) => {
    return state.seasons.find(season => season.id === parseInt(id))
  },

}

export default getters
