<template>

  <div>
    <div id="devise-admin-content">
      <action-bar>
        <li class="dvs-btn dvs-btn-sm dvs-mb-2" :style="theme.actionButton" @click.prevent="showCreateLocation">
          Create New Location
        </li>
      </action-bar>

      <h2 class="dvs-mb-10" :style="{color: theme.panel.color}">Current Locations</h2>

      <div v-for="location in locations" :key="location.id" class="dvs-mb-6 dvs-flex dvs-justify-between dvs-items-center">
        <div class="dvs-min-w-2/5 dvs-text-base dvs-font-bold dvs-pr-8">
          {{ location.name }}<br>
          <span class="dvs-font-normal dvs-text-xs" v-if="location.season">{{ location.season.years }} Season</span>
        </div>
        <div class="dvs-w-2/5 dvs-px-8 dvs-flex dvs-justify-end">
          <button class="dvs-btn dvs-btn-xs dvs-mr-2" @click="showEditLocation(location)" :style="theme.actionButtonGhost">Edit</button>
          <button class="dvs-btn dvs-btn-xs" v-devise-alert-confirm="{callback: requestDeleteLocation, arguments: location, message: 'Are you sure you want to delete this location?'}" :style="theme.actionButtonGhost">Delete</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'LocationsIndex',
  data () {
    return {
      modulesToLoad: 1
    }
  },
  mounted () {
    this.retrieveAllLocations()
  },
  watch: {
    language() {
      this.retrieveAllLocations();
    }
  },
  methods: {
    ...mapActions([
      'getLocations',
      'deleteLocation'
    ]),
    showEditLocation (location)  {
      this.$router.push({name: 'devise-locations-edit', params: {locationId: location.id}})
    },
    showCreateLocation (location)  {
      this.$router.push({name: 'devise-locations-create'})
    },
    requestDeleteLocation (location) {
      let self = this
      this.deleteLocation(location).then(function () {
        self.retrieveAllLocations()
      })
    },
    retrieveAllLocations (loadbar = true) {
      this.getLocations().then(function () {
        if (loadbar) {
          devise.$bus.$emit('incrementLoadbar', self.modulesToLoad)
        }
      })
    },
    originalLocation(id) {
      return this.locations.data.find(location => location.id === id)
    }
  },
  computed: {
    ...mapGetters([
      'locations'
    ])
  }
}
</script>
