<template>

  <div>

    <fieldset class="dvs-fieldset mb-8">
      <label>Label</label>
      <input
        type="text"
        v-model="value.label"
        placeholder="Label of the Donor Level"
      >
    </fieldset>

    <fieldset class="dvs-fieldset mb-8">
      <label>Donation Range</label>
      <input
        type="text"
        v-model="value.range"
        placeholder="Donation Range of the Donor Level"
      >
    </fieldset>

    <fieldset class="dvs-fieldset mb-8">
      <label>Order (lowest first)</label>
      <input
        type="text"
        v-model="value.sort"
        placeholder="Order of the Donor Level"
      >
    </fieldset>

  </div>

</template>

<script>
export default {
  name: 'DonorLevelsForm',
  methods: {
    updateValue() {
      // Emit the number value through the input event
      this.$emit('input', this.localValue);
      this.$emit('change', this.localValue);
    },
  },
  watch: {
    value() {
      this.updateValue();
    },
  },
  props: ['value'],
};
</script>
