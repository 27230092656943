<template>

  <div>
    
    <div id="devise-admin-content" v-if="donorToCreate">

      <h2 class="dvs-mb-10" :style="{color: theme.panel.color}">Create Donors: {{ donorToCreate.name }}</h2>

      <donors-form v-model="donorToCreate"></donors-form>

      <button class="dvs-btn" :style="theme.actionButton" @click="requestCreateDonors" :disabled="createInvalid">
        Create
      </button>
      <button class="dvs-btn" :style="theme.actionButtonGhost" @click="goToPage('devise-donors-index')">Cancel</button>
    </div>

  </div>

</template>

<script>
  import {mapActions} from 'vuex'
  
  import DonorsForm from './Form'
  
  export default {
    name: 'DonorCreate',
    data() {
      return {
        donorToCreate: {
          name: '',
          donor_level_id: ''
        }
      }
    },
    methods: {
      ...mapActions([
        'createDonor'
      ]),
      requestCreateDonors() {
        this.createDonor(this.donorToCreate).then(() => {
          this.goToPage('devise-donors-index')
        })
      }
    },
    computed: {
      createInvalid() {
        return this.donorToCreate.name === null
      }
    },
    components: {
      DonorsForm
    }
  }
</script>
